import React from 'react';
import CookieConsent from "react-cookie-consent";

const cookieConcent = () => {
  return (
    <CookieConsent 
      location="bottom" 
      cookieName="myAwesomeCookieName3" 
      expires={180}
      style={{
        background: "#f5f4f4",
        color: "#575757",
        width: "200px",
        bottom: "25px",
        right: "30px",
        left: "initial",
        fontFamily: "muli",
        margin: "5px, 15px 15px"
      }}
      buttonText="OKAY"
      buttonStyle={{
        background: "#575757",
        color: "#fff",
        fontSize: "0.7rem"
      }}

      contentClasses="text-capitalize"
      >
      This website uses cookies to enhance the user experience.
      <a className="lean-more" href="/privacy">Lean more</a>
    </CookieConsent>
  )
};

export default cookieConcent;
